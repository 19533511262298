<template>
  <div class="w-100">
    <pibot-page-heading>
      Inspection Planning
    </pibot-page-heading>

    <v-container>
      <!-- Planning revisions -->
      <pibot-survey-planning-revisions v-if="features.planning && features.revisions" />

      <!-- Planning block -->
      <pibot-survey-planning-table v-if="features.planning" class="py-5" />
    </v-container>
  </div>
</template>

<script>
import PageHeading from '@/components/typography/headings/PageHeading'
import config from '../../../config'

export default {
  name: 'pibot-survey-planning',
  components: {
    'pibot-page-heading': PageHeading,
    'pibot-survey-planning-table': () => import('./PlanningTable'),
    'pibot-survey-planning-revisions': () => import('./Revisions')
  },
  data () {
    return {
      features: config.features
    }
  }
}
</script>
